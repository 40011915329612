<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-body">
                    <h5 class="mb-3">{{ $t('claims.sign-modal.title') }}</h5>
                    <div class="p-3">
                        <Form @submit="onSubmit" v-show="claimId">
                            <div class="input-group">
                                <label class="form-label">{{ $t('claims.sign-modal.file-label') }}</label>
                                <input type="file" ref="file" class="form-control">
                            </div>
                            <Input
                                v-model="password"
                                name="password"
                                type="password"
                                :label="$t('claims.sign-modal.password-label')"
                                :placeholder="$t('claims.sign-modal.password-placeholder')"
                                :validations="['required']"
                            />
                            <button type="submit" class="btn btn-primary" :disabled="loading">
                                {{ $t('claims.sign-modal.sign') }}
                                <span class="loading" v-if="loading"></span>
                            </button>
                            <button type="button" class="btn btn-danger ms-3" data-bs-dismiss="modal" :disabled="loading">{{ $t('claims.sign-modal.cancel') }}</button>
                        </Form>
                        <p v-show="!claimId">Не удалось получить претензию</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="d-none" data-bs-dismiss="modal" ref="closeSignModal"></button>
    </div>
</template>

<script>

import { Form } from 'vee-validate'
import Input from '../form/Input.vue'

export default {
    name: 'SignModal',
    data: () => ({
        id: 'signModal',
        password: '',
        fileExtensions: ['p12'],
        loading: false
    }),
    props: {
        claimId: {
            type: Number,
            required: true
        }
    },
    components: {
        Form,
        Input
    },
    methods: {
        async onSubmit() {
            if(!(this.$refs.file.files[0] ?? false)) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.no-sign-file'),
                    type: 'error'
                })

                return
            }

            if(!this.checkFileExt(this.$refs.file.files[0])) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.wrong-ext-sign-file'),
                    type: 'error'
                })

                return
            }

            if(!this.password) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.no-sign-password'),
                    type: 'error'
                })

                return
            }

            await this.submit()
        },
        async submit() {
            if(this.loading) return
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/claims/' + this.claimId + '/sign'
            let file = this.$refs.file.files[0],
                filename = this.$refs.file.files[0].name

            let formData = new FormData()
            formData.append('file', file, filename)
            formData.append('password', this.password)

            if(process.env.VUE_APP_DEBUG) console.log('submit', this.password)

            let data = {}
            let signed = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            }).then(function (response) {
                if(process.env.VUE_APP_DEBUG) console.log('login', response.data)
                data = response.data

                return data.result == 'SUCCESS'
            }).catch(function (response) {
                if(process.env.VUE_APP_DEBUG) console.log('login error', response.data)
                data = { message: 'Error' }

                return false
            })

            this.loading = false

            if(signed) {
                this.$notify(data.message)
                this.$emit('signed')

                this.close()
            } else {
                this.password = ''

                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.signed-error'),
                    type: 'error'
                })
            }
        },
        getFileExt(filename) {
            return filename.split('.').pop()
        },
        checkFileExt(file) {
            return this.fileExtensions.includes(this.getFileExt(file.name))
        },
        close() {
            this.$refs.closeSignModal.click()
        }
    }
}

</script>

<style scoped>

.input-group {
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.input-group input.form-control:focus {
    border-color: #8f82d8;
    outline: 0;
    box-shadow: inset 0 0 0 1px #8f82d8;
}

.form-label {
    flex: 0 0 100%;
}

input {
    border-radius: .25rem;
}

input::placeholder {
    color: #cfcfcf;
}

.input-danger {
    border-color: #dc3545;
}

.input-warning {
    border-color: #ffc107;
}

.input-success {
    border-color: #198754;
}
</style>