<template>
    <div class="container-fluid">
        <Header />
        <div class="row h-100 justify-content-center align-items-center mt-15">
            <div class="col-md-8">
                <div class="container bg-light text-black p-5 rounded-3 position-relative">
                    <router-link class="buttonBack" to="/">
                        <i class="bi bi-chevron-left"></i>
                    </router-link>
                    <div class="text-center mb-4 title">
                        <h4>{{ $t('login.main.title') }}</h4>
                    </div>
                    <div class="row justify-content-between">
                        <div class="text-center border p-3 float-left w-48">
                            <h5>{{ $t('login.main.ip_title') }}</h5>
                            <p>{{ $t('login.main.ip_text') }}</p>
                            <div class="button-group">
                                <button class="custom-button btn-primary mb-2" @click="navigateTo('/login')">
                                    {{ $t('login.main.with_nca') }}
                                </button>
                                <button class="custom-button btn-primary" @click="navigateTo('/login/edocument')">
                                    {{ $t('login.main.with_sms') }}
                                </button>
                            </div>
                        </div>

                        <div class="text-center border p-3 float-left w-48">
                            <h5>{{ $t('login.main.legal_title') }}</h5>
                            <p>{{ $t('login.main.legal_text') }}</p>
                            <button class="custom-button btn-primary mb-2" @click="navigateTo('/login')">
                                {{ $t('login.main.with_nca') }}
                            </button>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12 text-center border p-3">
                            <h5>{{ $t('login.main.login_password_title') }}</h5>
                            <p class="p-0">
                                {{ $t('login.main.login_password_text') }}
                            </p>
                            <div class="col-md-8 mx-auto max-w-650">
                                <LoginForm backTo="/" type="login" @logged="logged" :backButton="false" class="p-0"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from './home/Header.vue'
    import LoginForm from 't3m-auth/src/components/Login.vue'

    export default {
        name: 'LoginMain',
        data: () => ({
            title: 'KDP'
        }),
        components: {
            Header,
            LoginForm
        },
        methods: {
            navigateTo(route) {
                this.$router.push(route);
            },
            logged(data = {}) {
                if(process.env.VUE_APP_DEBUG) console.log('logged', data)
                this.$router.push(this.$store.state.redirects.afterLogin)
                this.$store.commit('clearRedirects')
            }
        }
    }
</script>

<style scoped>
    #app > .container-fluid {
        min-height: 100vh;
        background-image: url('../assets/img/legal-papers-sign06.png');
        background-size: cover;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .custom-button {
        display: inline-block;
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        min-width: 120px;
        max-width: 250px;
    }

    .custom-button:hover {
        text-decoration: none;
    }

    .login-form input {
        max-width: 250px;
    }

    .max-w-650 {
        max-width: 650px;
    }

    .title p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
    }

    .text-center p {
        color: #5c5c5c;
    }

    h4 {
        font-weight: 400;
        font-size: 2em;
    }

    h5 {
        font-weight: 400;
        font-size: 1.5em;
    }

    .padding-10{
        padding: 0 10em 0 10em;
    }

    .w-48 {
        width: 48%;
    }

    .mt-15 {
        padding-top: 150px;
        padding-bottom: 100px
    }

    @media (max-width: 1200px) { 
        h4 {
            font-weight: 300;
            font-size: 1.5em;
        }

        h5 {
            font-weight: 300;
            font-size: 1.25em;
        }

        .p-0 {
            padding: 0 !important;
        }

        .w-48 {
            width: 100%;
            margin-bottom: 1rem; 
        }
    }
</style>