0<template>
    <div class="modal fade" :id="id" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header border-0">
                    <h3>{{ $t('claims.add.claim-type-header') }}</h3>
                    <div class="language-picker js-language-picker ms-auto">
                        <button :class="{ 'selected': isKazakh }" class="btn btn-light border-0 p-1 fs-6" @click="changeLanguage('kk')">Қазақша</button>
                        <button :class="{ 'selected': isRussian }" class="btn btn-light border-0 p-1 fs-6" @click="changeLanguage('ru')">Русский</button>
                    </div>
                    <button type="button" class="btn-close ms-0" ref="chooseEssenceClose" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row g-4">
                        <div class="col-md-6" v-for="e in essencesLocale">
                            <div class="card h-100 p-2" :class="{ 'card-active': e.id == essence }">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <h6 class="card-title">{{ $t('claims.type-modal.header.'+e.template)  }}{{ $t('claims.type-modal.header.'+e.language) }}</h6>
                                    <EssenceFavouriteStar @refreshFavourites="refreshFavourites" :essence="e.id" :user="this.$store.state.user.id"></EssenceFavouriteStar>
                                </div>
                                <div class="card-body d-flex flex-column justify-content-between">
                                    <p class="card-text">{{ $t('claims.type-modal.desc.'+e.template) }}</p>
                                    <button type="button" class="btn btn-secondary align-self-end" :class="{ active: e.id == essence }" :disabled="e.id == essence" @click="chooseEssence(e.id)">{{ e.id == essence ? $t('btn.chosen') : $t('btn.choose') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EssenceFavouriteStar from './EssenceFavouriteStar.vue'
    export default {
        name: 'chooseEssenceModal',
        data: () => ({
            id: 'chooseEssenceModal',
            isRussian: true,
            isKazakh: false,
            essencesLocale: []
        }),
        components: {
            EssenceFavouriteStar
        },
        props: {
            essences: {
                type: Array,
                default: []
            },
            essence: {
                type: String
            }
        },
        watch: {
            essences: {
                immediate: true,
                handler() {
                    if(this.isRussian) this.setEssences('ru')
                    else this.setEssences('kk')
                }
            }
        },
        methods: {
            chooseEssence(id) {
                if(this.essence == id) return
                this.$emit('setEssence', id)
                this.$refs.chooseEssenceClose.click()
            },
            changeLanguage(language) {
                if(language==="ru"){
                    this.isRussian = true
                    this.isKazakh = false
                }
                else if(language==="kk"){
                    this.isRussian = false
                    this.isKazakh = true
                }
                this.setEssences(language)
            },
            setEssences(language) {
                this.essencesLocale = []
                for(let essenceId in this.essences) {
                    if(this.essences[essenceId].language===language) this.essencesLocale.push(this.essences[essenceId])
                }
            },
            refreshFavourites() {
                this.$emit('refreshFavourites')
            }
        },
        mounted() {
            this.setEssences('ru')
        }
    }
</script>

<style scoped>

p {
    font-size: small;
    color: rgba(0, 0, 0, 0.7);
}

.active {
    background-color: #3a3077 !important;
    cursor: default;
}

.language-picker .btn {
    text-decoration: underline;
    text-decoration-style: dashed;
}

.btn-secondary:hover,
.btn-secondary:focus {
    background-color: #8d87e7 !important;
}

.card-active {
    background-color: #cac6ff;
}

.card-active .card-header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.selected {
    color: black;
    text-decoration: none !important;
}

h6 {
    margin-bottom: 0px !important;
}
</style>