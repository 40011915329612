import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
})

// Обработка ошибок
axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if(error.response) {
            console.error('Ошибка ответа от сервера:', error.response.status, error.response.data)
        } else if(error.request) {
            console.error('Ошибка запроса:', error.request)
        } else {
            console.error('Ошибка настройки запроса:', error.message)
        }

        return Promise.reject(error) // Возвращаем ошибку
    }
)

// ==============================
// Accounts
// ==============================

const getCurrentBalance = () => {
    return axiosInstance.get('/accounts/my')
}

// ==============================
// Claims
// ==============================

const checkClaims = (data) => {
    return axiosInstance.post('/claims/check-claims', data)
}

const checkNoticesHome = (data) => {
    return axiosInstance.post('/debt-notice/check-notices-home', data)
}

const getClaims = (params = {}) => {
    return axiosInstance.get('/claims', { params })
}

const getClaimById = (claimId, params = {}) => {
    return axiosInstance.get('/claims/' + claimId, { params })
}

const getClaimPreview = (slug) => {
    return axiosInstance.get(`/claims/${slug}/preview`)
}

const getNotificationMethods = (paid = false) => {
    return axiosInstance.get('/claims/notification-methods', { params: { paid } })
}

const getUnreadClaim = () => {
    return axiosInstance.get(`/claims/get-unreaded`)
}

const readClaim = (claimId) => {
    return axiosInstance.get(`/claims/${claimId}/read`)
}

const getClaimCost = () => {
    return axiosInstance.get('/claims/cost')
}

const getUnreadDebtNotice = () => {
    return axiosInstance.get(`/debt-notice/get-unread`)
}

const readNotice = (noticeId) => {
    return axiosInstance.get(`/debt-notice/${noticeId}/read`)
}

const deleteDebtNotice = (noticeId) => {
    return axiosInstance.post('/debt-notice/delete', { notice_id: noticeId })
}

const checkNotices = () => {
    return axiosInstance.get('/debt-notice/check-notices')
}

const checkNoticeStatuses = () => {
    return axiosInstance.get('/debt-notice/check-statuses')
}

const setNoticeSenderNotified = (noticeId) => {
    return axiosInstance.post('/debt-notice/sender-notified', { notice_id: noticeId })
}

const checkAddressRecipient = (address) => {
    return axiosInstance.post(`/debt-notice/check-address`, { address: address })
}

const addAddressVisibility = (addressId, userId) => {
    return axiosInstance.post('/debt-notice/add-address-visibility', { addressId, userId })
}

const checkOsiStatus = () => {
    return axiosInstance.get('/debt-notice/check-osi-status')
}

const importDebtNotices = (params) => {
    return axiosInstance.get('/debt-notice/check-imported-notices', { params })
}

const uploadDebtNotices = (body) => {
    return axiosInstance.post('/debt-notice/upload-import', body)
}

const getNoticeSenderData = (sender) => {
    return axiosInstance.post('/debt-notice/sender-data', { sender: sender })
}
const getSenderData = (userId) => {
    return axiosInstance.post('/debt-notice/sender-data-user-id', { userId: userId })
}

const addressRecipients = (addressId) => {
    return axiosInstance.post('/debt-notice/address-recipients', { addressId: addressId })
}

const getContactsLimits = () => {
    return axiosInstance.get('/claims/contacts-limits')
}

const sendClaimNotifications = (slug, method, contacts) => {
    return axiosInstance.post(`/claims/${slug}/send-notifications`, { method, contacts })
}

// ==============================
// Otp codes
// ==============================

const otpSendCode = (target) => {
    return axiosInstance.post('/signs/otp/send-code', { target })
}

const otpApproveCode = (sessionId, code) => {
    return axiosInstance.post('/signs/otp/approve-code', { sessionId, code })
}

// ==============================
// Messages
// ==============================

const getMessages = (slug) => {
    return axiosInstance.get(`/claims/${slug}/messages`)
}

const addMessage = (slug, data) => {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    }

    return axiosInstance.post(`/claims/${slug}/add-message`, data, config)
}

const messageAction = (slug, data) => {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    }

    return axiosInstance.post(`/claims/${slug}/message-action`, data, config)
}

// ==============================
// Recipients
// ==============================

const getRecipient = (recipientId) => {
    return axiosInstance.get(`/recipients/${recipientId}`)
}
const updateRecipientContact = (recipientId, key, value) => {
    return axiosInstance.post(`/recipients/contact/update`, { recipientId, key, value })
}

const removeRecipentContact = (key, contactId) => {
    return axiosInstance.post('/recipients/contact/remove', { key, contactId })
}

const getRecipentAddress = (recipientId) => {
    return axiosInstance.get(`/recipients/${recipientId}/address`)
}

// ==============================
// Transactions
// ==============================

const replenishAccount = (amount, paymentService) => {
    return axiosInstance.get('/transactions/replenish-account', { params: { amount, paymentService } })
}

const claimPayment = (slug) => {
    return axiosInstance.get(`/claims/${slug}/payment`)
}

const noticePayment = (slug) => {
    return axiosInstance.get(`/debt-notice/${slug}/payment`)
}

const getReceipt = (slug) => {
    return axiosInstance.get(`/receipts/${slug}`)
}

// ==============================
// Location
// ==============================

const getCountries = () => {
    return axiosInstance.get('/location/countries')
}

const getRegions = (countryId) => {
    return axiosInstance.get('/location/regions', { params: { countryId } })
}

const getLocalities = (regionId) => {
    return axiosInstance.get('/location/localities', { params: { regionId } })
}

const getSubLocalities = (localityId) => {
    return axiosInstance.get('/location/sub-localities', { params: { localityId } })
}

const getStreets = (localityId) => {
    return axiosInstance.get('/location/streets', { params: { localityId } })
}

const addStreet = (localityId, title) => {
    return axiosInstance.post('/location/streets', { localityId, title })
}

// ==============================
// Payment schedule
// ==============================

const getPaymentSchedules = (params = {}) => {
    return axiosInstance.get('/payment-schedules', { params })
}

const setManualPaymentSchedulePaid = (claimSlug, paymentScheduleId) => {
    return axiosInstance.post(`/claims/${claimSlug}/manual-payment-by-schedule`, { paymentScheduleId })
}

// ==============================
// Dashboard stats (statistics)
// ==============================

const getStats = (startDate, endDate) => {
    return axiosInstance.post('/stats/my', { startDate, endDate })
}

// ==============================
// Promocode
// ==============================

const redeemPromocode = (code) => {
    return axiosInstance.post('/promocodes/redeem', { code })
}

export default {
    addMessage,
    addStreet,
    checkClaims,
    claimPayment,
    otpApproveCode,
    otpSendCode,
    getCurrentBalance,
    getClaimCost,
    getClaimPreview,
    getContactsLimits,
    getCountries,
    getMessages,
    getReceipt,
    getRecipient,
    getRegions,
    getLocalities,
    getNotificationMethods,
    getPaymentSchedules,
    getRecipentAddress,
    getStats,
    getStreets,
    getSubLocalities,
    getUnreadClaim,
    getUnreadDebtNotice,
    checkNotices,
    checkNoticeStatuses,
    setNoticeSenderNotified,
    readNotice,
    deleteDebtNotice,
    checkAddressRecipient,
    addAddressVisibility,
    checkOsiStatus,
    importDebtNotices,
    uploadDebtNotices,
    getNoticeSenderData,
    checkNoticesHome,
    getSenderData,
    addressRecipients,
    messageAction,
    updateRecipientContact,
    readClaim,
    redeemPromocode,
    removeRecipentContact,
    replenishAccount,
    sendClaimNotifications,
    setManualPaymentSchedulePaid,
    noticePayment
}