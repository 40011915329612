<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header">
                    <h3 class="mb-0">Уведомление о задолженности {{ notice?.id }}</h3>
                </div>
                <div class="modal-body">
                    <p class="m-3">Пользователь указал адрес из ранее отправленного Вами уведомления о задолженности.</p>
                    <p class="m-3">Адрес: {{ recipientAddress }}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary mx-3" data-bs-dismiss="modal" @click="createClaim">Создать претензию</button>
                    <button class="btn btn-secondary mx-3" data-bs-dismiss="modal" @click="viewNotice">Просмотреть уведомление</button>
                    <button class="btn btn-danger mx-3" data-bs-dismiss="modal" @click="handleClose">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { shortAddress } from '../../../libs/location'

    export default {
        name: 'notifyNoticeSenderModal',
        data: () => ({
            modalId: 'notifyNoticeSenderModal'
        }),
        computed: {
            recipientAddress() {
                let address = shortAddress({
                    street: this.notice.street,
                    buildingNumber: this.notice.building_number,
                    apartment: this.notice.apartment,
                    extra: this.notice.extra ?? ''
                })

                return address
            }
        },
        props: {
            notice: {
                type: Object,
                default: {}
            }
        },
        methods: {
            handleClose() {
                this.$emit('closed')
            },
            async createClaim() {
                if(!this.notice.recipient) return
                if(!this.notice.address_id) return

                let r = await this.addRelation(this.$store.state.user.id, this.notice.recipient)

                await this.$api.addAddressVisibility(this.notice.address_id, this.$store.state.user.id)

                if(!r) return
                this.$router.push(`/claims/new?recipient=${this.notice.recipient}&essence=osi&amount_owed=${this.notice.amount_owed}`)
            },
            async addRelation(userId, recipientId) {
                let data = {
                    userId: userId,
                    recipientId: recipientId
                }
                
                let url = process.env.VUE_APP_API_URL + '/recipients/relations'

                let result = await axios.post(url, data, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }
                })

                return result
            },
            viewNotice() {
                if(!this.notice.id) return

                this.$router.push(`/debt-notice/${this.notice.id}`)
            }
        }
    }

</script>