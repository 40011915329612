<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row my-3">
            <div class="col-md-7 order-md-1 order-2">
                <div v-if="loading" class="d-flex justify-content-center align-items-center">
                    <span :class="{ loading: loading }"></span>
                </div>
                <ClaimText
                    v-if="!loading"
                    ref="editClaimText"
                    :text="content"
                    :real-claim="realClaim"
                    :claim="claim"
                    :preview="true"
                    @templateFields="templateFields"    
                />
            </div>
            <div class="col-md-5 order-md-2 order-1">
                <Form 
                    ref="editClaimForm"
                    :claim = "claim"
                    :claimId="realClaim.id"
                    :claimIsEdited="claimIsEdited"
                    @cancel = "cancel"
                    @remove = "remove"
                    @setClaim = "setClaim"
                    @updateCounters = "updateCounters"
                    @updateBalance = "updateBalance"
                    @getClaim = "getClaim"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Form from './Form.vue'
    import ClaimText from './ClaimText.vue'
    import { generate } from '../../../libs/generator'

    export default {
        data: () => ({
            claim: {},
            content: '',
            realClaim: {},
            recipient: {},
            loading: false,
            claimIsEdited: false
        }),
        components: {
            Form,
            ClaimText
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', 'Редактирование')

            this.getClaim()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', 'Редактирование')
            },
            async getClaim() {
                this.claimIsEdited = false
                this.loading = true
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.$route.params.id + '/edit'

                await axios.get(url, {
                    withCredentials: true
                }).then(async (response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    let r = response.data
                    let r1 = r

                    if(r1.result === 'SUCCESS') {
                        this.claim = r1.data
                        
                        this.claim.recipient = await this.getRecipient(this.claim.recipient.id)
                        this.claim.sender = await this.getSender(this.claim.sender)
                        
                        this.$refs.editClaimForm.load(this.claim)
                        this.$refs.editClaimForm.getEditClaim()

                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('claim.notify.success'),
                            type: 'success'
                        })

                        if(this.claim.edited === 1) this.claimIsEdited = true

                        this.content = this.claim.edited === 1 ? this.claim.content : generate(this.claim.template, this.claim, this.claim.sender, this.claim.recipient, this.claim.essenceLanguage)

                        this.realClaim = this.claim
                    } else {
                        this.$router.go(-1)
                        
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claim.notify.error'),
                            type: 'error'
                        })
                    }
                })

                this.loading = false
            },
            cancel() {
                this.$router.push(`/claims/outbox`)
            },
            async remove() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.$route.params.id + '/remove'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)
                }).catch((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)
                })

                this.updateCounters()
                this.$router.push('/claims/outbox')
            },
            setClaim(claim) {
                this.claim = claim
            },
            updateCounters() {
                this.$emit('updateCounters')
            },
            updateBalance() {
                this.$emit('updateBalance')
            },
            async getRecipient(id) {
                let url = process.env.VUE_APP_API_URL + '/recipients/' + id
                if(process.env.VUE_APP_DEBUG) console.log('id', id)
                let recipient = {}

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        recipient = r.data
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error'),
                            type: 'error'
                        })
                    }
                })

                return recipient
            },
            async getSender(id) {
                let url = process.env.VUE_APP_API_URL + '/recipients/' + id
                let sender = {}

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        sender = r.data
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error'),
                            type: 'error'
                        })
                    }
                })

                return sender
            },
            templateFields(fields) {
                this.$refs.editClaimForm.templateFields = fields
            },
            changedTemplateFields(fields) {
                this.$refs.editClaimText.updateTemplateFields(fields)
                // if(this.$refs.claimText.$refs.templateDefault.$refs?.template) {
                //     this.$refs.claimText.$refs.templateDefault.$refs?.template.fields = fields
                // }
            }
        }
    }

</script>

<style scoped>

.back-button {
    margin-right: .5em;
    color: #8f82d8;
    cursor: pointer;
}

</style>