<template>
    <div class="main mb-3">
        <div 
            class="dropzone-container"
            :class="isDragging ? 'is-dragging' : 'inactive'"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
        >
            <input 
                type="file"
                name="file"
                id="fileInput"
                class="hidden-input"
                :multiple="multiple"
                @change="onChange"
                ref="fileInput"
            />
            <label for="fileInput" class="file-label text-muted">
                <div v-if="isDragging">{{ $t('form.file-upload.is-dragging') }}</div>
                <div v-else>{{ $t('form.file-upload.inactive') }}</div>
            </label>
            <div class="preview-container mt-4" v-if="files.length">
                <div v-show="loading" class="loading"></div>
                <div v-for="file in files" :key="file.id" class="preview-card">
                    <div>
                        <i :class="'preview-icon bi bi-filetype-' + getExtension(file)"></i>
                        <p class="preview-text">{{ cutName(file.name ?? file.title) }}</p>
                    </div>
                    <div class="preview-remove">
                        <button
                            class="btn btn-link m-0 p-0"
                            type="button"
                            @click="remove(file.id)"
                        >
                            <i class="bi bi-x-square-fill fs-5"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data: () => ({
            loading: false,
            isDragging: false,
            changed: false,
            files: [],
            limit: 100
        }),
        props: {
            multiple: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onChange() {
                this.$emit('added', [...this.$refs.fileInput.files])
            },
            dragover(e) {
                e.preventDefault()
                this.isDragging = true
            },
            dragleave() {
                this.isDragging = false
            },
            drop(e) {
                e.preventDefault()
                this.$refs.fileInput.files = e.dataTransfer.files
                this.onChange()
                this.isDragging = false
            },
            remove(id) {
                this.$emit('removed', id)
            },
            clear() {
                this.$refs.fileInput.value = null
            },
            getExtension(file) {
                let name = file.name ?? file.title

                return name.split('.').pop().toLowerCase()
            },
            cutName(str) {
                return this.$libs.text.cutStr(str, 32, '...')
            }
        }
    }

</script>

<style scoped>

.main {
    display: block;
    text-align: center;
}

.dropzone-container {
    padding: 1rem;
    border-radius: 10px;
    border: 2px dashed #bdbdbd;
    transition: .25s;
    -o-transition: .25s;
    -moz-transition: .25s;
    -webkit-transition: .25s;
}

.dropzone-container.is-dragging {
    border-color: #8f82d8;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    display: block;
    color: #bdbdbd;
    cursor: pointer;
}

.dropzone-container.is-dragging .file-label {
    color: #8f82d8;
}

.preview-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    overflow-x: auto;
}

.preview-card {
    position: relative;
    display: flex;
    flex: 0 0 25%;
    justify-content: center;
    max-width: 150px;
    margin-left: 5px;
    padding: 5px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
}

.preview-icon {
    font-size: 2em;
}

.preview-text {
    margin-bottom: 0;
    font-size: .85em;
    word-wrap: break-word;
    word-break: break-word;
}

.preview-remove button {
    position: absolute;
    color: #8f82d8;
    top: 5px;
    right: 5px;
    border: none;
    background-color: transparent;
}

</style>