<template>
    <div id="content" class="col py-4 px-5">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="d-flex align-items-center">
            <span class="back-button" @click="goToClaim">
                <i class="bi bi-chevron-left"></i>
            </span>
        </div>
        <div class="row my-3">
            <div class="col-md-7 order-md-1 order-2">
                <Editor class="mt-3" ref="editorComponent" :editorConfig="editorConfig" @ready="editorReady" @data-saved="logSavedData" />
            </div>
            <div class="col-md-5 order-md-2 order-1 mt-3">
                <div class="card">
                    <div class="card-header">
                        <h5>{{ $t('claim-editor.buttons.header') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row mb-3 justify-content-center">
                            <div class="col col-md-6"><button type="button" class="add-btn btn btn-outline-primary fw-bold h-100 align-items-center" @click="addDetail('totalAmount')">{{ $t('claim-editor.buttons.total-amount') }}</button></div>
                            <div class="col col-md-6"><button type="button" class="add-btn btn btn-outline-primary fw-bold h-100 align-items-center" @click="addDetail('amountOwed')">{{ $t('claim-editor.buttons.amount-owed') }}</button></div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row my-3 justify-content-center">
                            <span class="loading" v-show="loading"></span>
                            <button type="button" class="btn btn-primary w-auto mx-1" :class="{ disabled: loading }" @click="save">{{ $t('claim-editor.buttons.save') }}</button>
                            <button type="button" class="btn btn-warning w-auto mx-1" :disabled="claim.edited!==1" @click="showResetModal()">{{ $t('claim-editor.reset') }}</button>
                            <button type="button" class="btn btn-outline-danger w-auto mx-1" @click="cancel">{{ $t('cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ResetTextModal
        ref="resetTextModal"
        :header="resetTextHeader"
        :body="resetTextBody"
        @reset="resetText()"
    />
</template>

<script>
import Editor from './Editor.vue'
import ResetTextModal from './textEditor/ResetTextModal.vue'

    export default {
        data: () => ({
            text: '',
            savedText: '',
            claim: '',
            loading: false
        }),
        computed: {
            editorConfig: {
                get() {
                    let config = {
                        placeholder: '',
                        tools: {},
                        inlineToolbar: ['bold'],
                        data: {
                            blocks: []
                        }
                    }

                    let claim = false
                    let text = ''

                    if(this.claim) {
                        claim = this.claim
                        text = claim.content
                    } else {
                        claim = this.$store.state.claim
                        text = claim.text
                    }

                    if(text) text = text.replace(/<\/p> <p>/, '</p><p>')
                    
                    if(text) {
                        let textArr = text.split('<p>')
                        
                        for(let p in textArr) {
                            p.replace('</p>', '')
    
                            config.data.blocks.push({
                                type: 'paragraph',
                                data: {
                                    text: textArr[p]
                                }
                            })
                        }
        
                        return config
                    }

                    return {
                        placeholder: '',
                        tools: {},
                        inlineToolbar: ['bold'],
                        data: {
                            blocks: [{
                                type: 'paragraph',
                                data: {
                                    text: this.text
                                }
                            }]
                        }
                    }
                }
            },
            resetTextHeader: {
                get() {
                    return `<h3>${this.$t('claim-editor.reset-modal-header')}</h3>`
                }
            },
            resetTextBody: {
                get() {
                    return `<p>${this.$t('claim-editor.reset-modal-body')}</p>`
                }
            }
        },
        components: {
            Editor,
            ResetTextModal
        },
        created() {
            this.text = this.getClaimText()
        },
        methods: {
            editorReady() {
                if(!this.$route.params.id) return
                this.getClaim()
            },
            pushTextToEditor(content) {
                content = content.replace(/<div>/g, '').replace(/<\/div>/g, '').replace(/<p><\/p>/g, '')

                if(content) content = content.replace(/<\/p> <p>/, '</p><p>')

                let blocks = this.getDataFromText(content)
                this.$refs.editorComponent.editor.save().then((outputData) => {
                    outputData.blocks = blocks

                    this.$refs.editorComponent.editor.render(outputData)
                })
            },
            getDataFromText(content) {
                const temporaryElement = document.createElement('div')
                temporaryElement.innerHTML = content

                const blocks = []
                temporaryElement.childNodes.forEach(node => {
                    const blockType = node.nodeName.toLowerCase()

                    let block = {}

                    if(blockType === 'p') {
                        block = {
                            type: 'paragraph',
                            data: {
                                text: node.innerHTML
                            }
                        }
                    }

                    blocks.push(block)
                })

                return blocks
            },
            async getClaim() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.$route.params.id + '/edit'

                await axios.get(url, {
                    withCredentials: true
                }).then(async (response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.claim = r.data

                        this.pushTextToEditor(this.claim.content)

                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('claim.notify.success'),
                            type: 'success'
                        })
                    } else {
                        this.$router.go(-1)
                        
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claim.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            addDetail(arg) {
                let editorInstance = this.$refs.editorComponent.editor
                // let editorInstance = editorComponent.getEditorInstance()

                let span = document.createElement('span')
                span.classList.add(`_${arg}_`)
                span.classList.add('shortcode')
                span.setAttribute('data-slug', `_${arg}_`)

                if(arg == 'totalAmount') {
                    span.setAttribute('data-text', this.$store.state.claim.template_fields.total_amount.field_value || $t('claim-editor.buttons.total-amount'))
                    span.textContent = this.$store.state.claim.template_fields.total_amount.field_value || $t('claim-editor.buttons.total-amount')
                } else if(arg == 'amountOwed') {
                    span.setAttribute('data-text', this.$store.state.claim.amount_owed || $t('claim-editor.buttons.amount-owed'))
                    span.textContent = this.$store.state.claim.amount_owed || $t('claim-editor.buttons.amount-owed')
                }

                let selection = window.getSelection()

                if(selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0)
                    range.deleteContents()
                    range.insertNode(span)
                }
            },
            async save() {
                let str = ''
                await this.$refs.editorComponent.editor.save()
                .then((savedData) => {
                    str = this.$refs.editorComponent.renderData(savedData)
                    this.savedText = str

                    if(this.$route.params.id && this.claim) {
                        this.saveClaimContent(str)
                    } else {
                        this.$store.commit('getClaim')
    
                        let claim = this.$store.state.claim

                        claim.newTemplate = this.savedText

                        this.$store.commit('setClaim', claim)

                        this.$router.push('/claims/new')
                    }
                })
            },
            async saveClaimContent(content) {
                if(this.loading) return
                this.loading = true

                let url = process.env.VUE_APP_API_URL + '/claims/' + this.$route.params.id + '/save-content'

                let body = {
                    content: content
                }

                await axios.post(url, body, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.$router.push('/claims/' + this.$route.params.id + '/edit')
                    }
                })

                this.loading = false
            },
            getClaimText() {
                let claim = this.$store.state.claim

                return claim.text
            },
            cancel() {
                this.$router.push('/claims/' + this.$route.params.id + '/edit')
            },
            showResetModal() {
                this.$store.commit('modalShow', { id: 'resetTextModal' })
            },
            async resetText() {
                let url = process.env.VUE_APP_API_URL + '/claims/reset-text'

                await axios.post(url, {
                    slug: this.claim.slug
                }, {
                    withCredentials: true
                }).then(response => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.pushTextToEditor(r.content)
                        this.claim.edited = 0
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message,
                            type: 'error'
                        })
                    }
                })
            }
        }
    }

</script>

<style scoped>

.add-btn {
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
}

.back-button {
    margin-right: .5em;
    color: #8f82d8;
    cursor: pointer;
}

</style>