<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="page-section">
                <h1 class="text-uppercase">{{ title }}</h1>
                <div class="content mt-5" v-html="content"></div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './home/Header.vue'
import Footer from './home/Footer.vue'

export default {
    data: () => ({
        loading: false,
        slug: '',
        title: '',
        content: '',
        date: ''
    }),
    components: {
        Header,
        Footer
    },
    methods: {
        async get() {
            if(this.loading) return
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/pages/' + this.language + '/' + this.slug

            await axios.get(url).then((response) => {
                if(process.env.VUE_APP_DEBUG) console.log(response)

                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.title = r.data.title
                    this.content = r.data.content
                    this.date = r.data.date

                    document.title = this.title + ' | KDP'

                    if(r.data.description) {
                        this.createMeta(r.data.description)
                    }

                    this.setBreadcrumb()
                } else {
                    this.$router.push(`/${this.language}/`)
                }
            })
            this.loading = false
        },
        createMeta(content) {
            this.$emit('createMeta', content)
        },
        setBreadcrumb() {
            this.$breadcrumbs.value[0].label = this.title
        }
    },
    created() {
        this.slug = this.$route.params.slug
        this.language = this.$route.params.lang
    },
    mounted() {
        this.get()
    },
    beforeRouteLeave(to, from, next) {
        document.title = process.env.VUE_APP_TITLE
        next()
    }
}

</script>

<style scoped>

    .container-fluid {
        background-color: #f3f3f3;
    }

    section {
        padding: 2em 1em;
    }

    .page-section {
        padding-top: 140px;
        padding-bottom: 5em;
        min-height: 70vh;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }

    .figure-img {
        width: 600px;
        max-width: 80vw;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }



</style>