<template>
    <Form class="shadow p-3 mb-5 rounded" @submit="onSubmit">
        <div class="row align-items-start">
            <div class="col-xl-6 mb-3 d-flex flex-column">
                <label class="form-label">{{ $t('claims.add.claim-type') }}</label>
                <button v-if="!claim" type="button" class="btn btn-secondary mb-3" :disabled="claim||essenceBlocked" @click="chooseType">{{ tmpClaim.essence }}</button>
                <button v-else type="button" class="btn btn-dark mb-3" :disabled="claim">{{ tmpClaim.essence }}</button>
            </div>
        </div>
        <div v-if="claimIsEdited" class="row align-items-start mb-3">
            <p class="fw-light text-secondary"><i>{{ $t('claims.edit.claim-is-edited') }}</i></p>
        </div>
        <div class="row align-items-center">
            <div class="input-group mb-3">
                <input
                    v-model="displayedTitle"
                    type="text"
                    class="form-control border-end-0"
                    aria-label="Recipient"
                    :placeholder="$t('claims.add.choice-recipient')"
                    :validations="['required']"
                    @change="onFormChange" readonly
                    :disabled="claimIsEdited"
                >
                <button v-if="recipientId&&!claimIsEdited" type="button" class="btn btn-link border-top border-bottom btn-clear" @click="removeRecipient" :disabled="claimIsEdited"><i class="bi bi-x-circle"></i></button>
                <button v-if="recipientId" type="button" class="btn btn-primary btn-input" @click="recipientCardModal"><i class="bi bi-person-vcard"></i></button>
                <button type="button" class="btn btn-primary btn-input" @click="searchRecipientModal()" :disabled="claimIsEdited"><i class="bi bi-search"></i></button>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-12">
                <Input
                    v-model="occurrence_date"
                    name="occurrence_date"
                    type="date"
                    :label="$t('claims.add.occurrence-date')"
                    :placeholder="$t('claims.add.occurrence-date')"
                    :max="todayDate"
                    :validations="['date', 'dateCompare']"
                    @change="onFormChange"
                    :disabled="claimIsEdited"
                />
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-12"> 
                <Input
                    v-model="amount_owed"
                    name="amount_owed"
                    type="number"
                    :label="$t('claims.add.amount-owed')"
                    :placeholder="$t('claims.add.amount-owed')"
                    :validations="['required', 'min(0)']"
                    @change="onFormChange"
                    :disabled="claimIsEdited"
                    maska="################"
                />
            </div>
        </div>
        <div class="template-fields" v-if="templateFieldsArray.length">
            <hr/>
            <div class="row align-items-center" v-for="field in templateFieldsArray">
                <div class="col-md-12">
                    <Input
                        v-if="field.type!=='select'"
                        :name="field.name"
                        :type="field.type"
                        :label="field.label"
                        :placeholder="field.label"
                        :model-value="field.field_value"
                        :validations="field.validations"
                        @change="onTemplateFieldChange"
                        :disabled="claimIsEdited"
                        :maska="field.maska"
                    />
                    <Select2
                        v-if="field.type=='select'"
                        :name="field.name"
                        :label="field.label"
                        :placeholder="field.label"
                        :options="field.options"
                        :model-value="field.field_value"
                        :search="true"
                        @change="onSelectChange"
                        :disabled="claimIsEdited"
                    />
                </div>
            </div>
        </div>
        <button v-if="recipientId && essence == 4" type="button" class="btn btn-outline-secondary mb-3" @click="addAddressOsi">{{ $t('claims.add.add-address-osi') }}</button>
        <FileUpload
            v-show="false"
            ref="file"
            :multiple="true"
            @added="addedFiles"
            @removed="removeFile"
            @change="onChangeFile()"
            @changeName="setAttacmentTmpName($event)"
            @changed="onFileUploadChanged"
        />
        <div class="row align-items-start">
            <div class="col-md-12">
                <Select2
                    v-model="notificationMethod"
                    :name="'notificationMethodOptionsSelect'"
                    :label="$t('claims.add.notification-label')"
                    :options="notificationMethods"
                    :search="false"
                    @change="onFormChange"
                />
            </div>
        </div>
        <div class="row align-items-start mb-3" v-if="hasCredentials">
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="pretrialCheckbox" v-model="pretrialAgreement">
                    <label class="form-check-label" for="pretrialCheckbox">
                        {{ $t('claims.add.pretrial-agreement')}}
                    </label>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap align-items-center gap-2">
            <button type="submit" class="btn btn-primary" v-if="showSendBtn&&!loader" :class="{ disabled: !(requiredFilled && templateFieldsFilled) || (essence == 14 ? !claimIsEdited : false) }">{{ $t('claims.add.send-claim') }}</button> <!-- ОТПРАВИТЬ -->
            <button type="button" class="btn btn-primary" v-if="showSendBtn&&loader" disabled>
                <span class="loading d-flex align-items-center"></span>
            </button>
            <button type="submit" class="btn btn-secondary" v-if="!loader" :class="{ disabled: !(requiredFilled && templateFieldsFilled) || (essence == 14 ? !claimIsEdited : false) }" @click="sendClaim = false">{{ $t('claims.add.save-claim') }}</button> <!-- СОХРАНИТЬ -->
            <button type="button" class="btn btn-secondary" v-if="loader" disabled>
                <span class="loading save-loader d-flex align-items-center"></span>
            </button>
            <button type="button" class="btn btn-danger" v-if="claim" @click="showDeleteDraftModal">{{ $t('claim.btn-remove') }}</button> <!-- УДАЛИТЬ -->
            <button type="button" class="btn btn-outline-danger" v-if="claim" @click="cancel">{{ $t('claims.edit.cancel') }}</button> <!-- ОТМЕНА -->
            <span class="d-inline-block" v-if="!requiredFilled" data-bs-toggle="tooltip" :data-bs-title="$t('claims.add.editor-tooltip')">
                <button type="button" class="btn btn-outline-secondary" :class="{ disabled: !(requiredFilled && templateFieldsFilled) }">
                    {{ $t('claim-editor.button') }}
                </button>
            </span>
            <span class="d-inline-block" v-else>
                <button type="button" class="btn btn-outline-secondary" @click="goToEditor" :class="{ disabled: !(requiredFilled && templateFieldsFilled) }">
                    {{ $t('claim-editor.button') }}
                </button>
            </span>
        </div>
    </Form>
    <SearchRecipientModal ref="searchRecipientModal" @recipientAdded="recipientAdded($event)" />
    <ConfirmFreeSendModal ref="confirmFreeSendModal" @confirmed="handleFreeSendConfirmed" @stopLoader="stopLoader" @closed="handleFreeSendClosed" />
    <ConfirmSendModal ref="countModal" @confirmed="confirmed" @stopLoader="stopLoader" />
    <ChooseEssenceModal :essences="essences" :essence="essence" @setEssence="setEssence" @refreshFavourites="refreshFavourites"/>
    <RecipientCardModal ref="recipientCardModal" :recipient="tmpClaim.recipient" @added="recipientAdded" />
    <ChoiceContactsModal
        ref="choiceContactsModal"
        :emails-limit="contactsLimits.emails"
        :phones-limit="contactsLimits.phones"
        :addresses-limit="contactsLimits.addresses"
        @selected="handleChoiceContctsSelected"
        @closed="handleChoiceContctsClosed"
    />
    <ConfirmDeleteDraft ref="deleteDraftModal" :body="$t('claim.remove-modal.text')" @deleteObj="remove" />
</template>

<script>
    import { Form } from 'vee-validate'
    import Input from '../form/Input.vue'
    import Select from '../form/Select.vue'
    import Select2 from '../form/Select2.vue'
    import FileUpload from '../form/FileUpload.vue'

    import ClaimTextModal from './ClaimTextModal.vue'
    import SearchRecipientModal from '../recipients/modals/SearchRecipientModal.vue'
    import ConfirmFreeSendModal from './ConfirmFreeSendModal.vue'
    import ConfirmSendModal from './ConfirmSendModal.vue'
    import ConfirmDeleteDraft from './ConfirmDeleteDraftModal.vue'
    import ChooseEssenceModal from './ChooseEssenceModal.vue'
    import SignModal from './SignModal.vue'
    import RecipientCardModal from '../recipients/Modal.vue'
    import AddRecipientContactsModal from '../recipients/modals/AddRecipientContactsModal.vue'
    import ChoiceContactsModal from './ChoiceContactsModal.vue'
    import { addZeros, shortenCompanyName } from '../../../libs/text'

    export default {
        data: () => ({
            recipientId: '',
            recipient: {},
            sender: '',
            senderData: {},
            occurrence_date: '',
            essence: '1',
            contract_id: '',
            amount_owed: '',
            attachmentName: '',
            attachments: [],
            attachmentsData: [],
            notification: '',
            recipients: [],
            essences: [],
            notifications: [],
            uploadedFileExt: '',
            uploadedFiles: [],
            sendClaim: true,
            showSendBtn: true,
            paymentOptions: [{ id: false, text: 'free' }],
            notificationMethod: 0,
            notificationMethods: [],
            notificationMethodsArr: [{ free: { id: 0 } }],
            paidDelivery: false,
            hasCredentials: false,
            claimCost: 0,
            currentBalance: 0,
            contactsLimits: { emails: 0, phones: 0, addresses: 0 },
            contactsLimitsObj: {
                free: { emails: 0, phones: 0, addresses: 0 }
            },
            isLoading: false,
            pretrialAgreement: false,
            requiredFilled: false,
            templateFieldsFilled: false,
            templateFields: {},
            contacts: {},
            loader: false,
            loadingBalance: false,
            created: '',
            essenceBlocked: false
        }),
        props: {
            claim: {
                type: Object,
                default: false
            },
            claimId: {
                type: Number,
                default: null
            },
            claimIsEdited: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            _paymentOptions: {
                get() {
                    let arr = this.paymentOptions
                    for(let i in arr) {
                        if(arr[i].text == 'free') arr[i].text = this.$t('claims.add.delivery-methods.free')
                        if(arr[i].text == 'paid') arr[i].text = this.$t('claims.add.delivery-methods.paid', { price: this.claimCost })

                        if(arr[i].id){
                            arr[i].disabled = parseFloat(this.currentBalance) < parseFloat(this.claimCost)
                        }
                    }

                    return arr
                }
            },
            templateFieldsArray: {
                get() {
                    let arr = []
                    for(let field_name in this.templateFields) {
                        let field = this.templateFields[field_name]

                        field.name = field_name
                        field.label = this.$t(field.label)

                        arr.push(field)
                    }

                    return arr
                }
            },
            displayedTitle: {
                get() {
                    return this.recipient.company_name ? shortenCompanyName(this.recipient.company_name) : this.recipient.title
                },
                set(value) {
                    this.recipientId = value
                }
            },
            todayDate: {
                get() {
                    return this.getTodayDate()
                }
            },
            templates: {
                get() {
                    return {
                        dogovor: this.$t('essences.templates.dogovor'),
                        header: this.$t('essences.templates.header'),
                        mfo: this.$t('essences.templates.mfo'),
                        mfogarant: this.$t('essences.templates.mfogarant'),
                        mforazbivka: this.$t('essences.templates.mforazbivka'),
                        osi: this.$t('essences.templates.osi'),
                        vozvrat: this.$t('essences.templates.vozvrat'),
                        zadatok: this.$t('essences.templates.zadatok'),
                        zp: this.$t('essences.templates.zp')
                    }
                }
            },
            tmpClaim: {
                get() {
                    // transform occurrence_date to timestamp
                    let occurrenceDateTs = new Date(this.occurrence_date),
                        occurrence_date = Math.floor(occurrenceDateTs / 1000),
                        ts = Math.floor(Date.now() / 1000)

                    let recipient = {}
                    if(this.recipient) {
                        recipient = this.recipient
                    }

                    let essence = '',
                        template = '',
                        language = ''

                    for(let i in this.essences) {
                        if(this.essences[i].id == this.essence) {
                            essence = this.essences[i].text || $t('claim-text.default-essence')
                            if(this.templates[this.essences[i].template] ?? false) {
                                essence = this.templates[this.essences[i].template]
                            }
                            template = this.essences[i].template ? this.essences[i].template : ''
                            language = this.essences[i].language
                        }
                    }


                    return {
                        recipient: recipient,
                        sender: this.senderData,
                        occurrence_date: occurrence_date,
                        essence: essence,
                        amount_owed: this.amount_owed,
                        template: template,
                        template_language: language,
                        template_fields: this.templateFields,
                        created: ts,
                        paid_delivery: this.paidDelivery,
                        notification_method: this.notificationMethod
                    }
                }
            }
        },
        components: {
            Form,
            Input,
            Select,
            Select2,
            FileUpload,
            ClaimTextModal,
            SearchRecipientModal,
            ConfirmFreeSendModal,
            ConfirmSendModal,
            ConfirmDeleteDraft,
            ChooseEssenceModal,
            SignModal,
            RecipientCardModal,
            AddRecipientContactsModal,
            ChoiceContactsModal
        },
        created() {
            this.showSendBtn = this.$store.state.user.settings.canSignFromCompany ?? true
        },
        mounted() {
            this.$emit('updateBalance')
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
            tooltipTriggerList.forEach(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

            if(!this.claim) {
                this.setEssence(1) // fix for new claim empty essence after visiting draft
            }
            
            if(!this.claim) {
                // set today by default
                this.occurrence_date = this.todayDate
                this.getSavedClaim()
            } else {
                this.getEditClaim()
            }
            this.getEssences(true)
            // this.getNotifications()
            this.getSenderId()
            this.getNotificationMethods()
        },
        watch: {
            amount_owed: function() {
                this.onFormChange()
            },
            recipientId: function() {
                if(!this.claim) this.onFormChange()
            },
            essence: function() {
                if(!this.claim) this.onFormChange()
            },
            occurrence_date: function() {
                if(!this.claim) this.onFormChange()
            }
        },
        methods: {
            getEditClaim() {

                this.getSenderId()
            },
            getSavedClaim() {
                if(this.claim) return

                this.isLoading = true

                // load from store && localStorage (if have)
                this.$store.commit('getClaim')

                let savedClaim = this.$store.state.claim

                this.load(savedClaim)

                this.isLoading = false

                this.getSenderId()
            },
            getDateLocale(date) {
                return date.getFullYear() + '-' + addZeros(date.getMonth() + 1, 2) + '-' + addZeros(date.getDate(), 2)
            },
            load(claim) {
                for(let k in claim) {
                    switch (k) {
                        case 'recipient':
                            this.recipient = claim[k]
                            this.recipientId = this.recipient.id
                            break;
                        case 'sender':
                            this.sender = claim[k]
                            break;
                        case 'occurrence_date':
                            if(claim[k]) {
                                if(typeof claim[k] === 'number') {
                                    let date = new Date(claim[k]*1000)
    
                                    this.occurrence_date = this.getDateLocale(date)
                                } else {
                                    this.occurrence_date = claim[k]
                                }
                            }
                            break;
                        case 'essence':
                            if(claim[k].id ?? false) {
                                this.essence = claim[k].id
                            } else {
                                if(!this.essenceBlocked) this.essence = claim[k]
                            }
                            break;
                        case 'contract_id':
                            this.contract_id = claim[k]
                            break;
                        case 'amount_owed':
                            this.amount_owed = claim[k]
                            break;
                        case 'attachments':
                            this.attachments = claim[k]
                            break;
                        case 'attachmentsData':
                            this.attachmentsData = claim[k]
                            this.$refs.file.files = this.attachmentsData
                            break;
                        case 'notifications':
                            this.notification = claim[k]
                            break;
                        case 'paid_delivery':
                            this.paidDelivery = !!claim[k]
                            break;
                        case 'notification_method':
                            this.notificationMethod = claim[k]
                            break;
                        case 'pretrial_agreement':
                            this.pretrialAgreement = !!claim[k]
                            break;
                        case 'template_fields':
                            this.templateFields = claim[k]

                            this.templateFieldsFilled = true
                            // this.templateFields = JSON.parse(claim[k])

                            // this.setTemplateFields()
                        default:
                            break;
                    }
                }

                if(this.notificationMethod) {
                    for(let i in this.notificationMethods) {
                        if(this.notificationMethod === this.notificationMethods[i].id) {
                            this.claimCost = this.notificationMethods[i].cost
                            if(this.contactsLimitsObj[this.notificationMethods[i].title] ?? false) {
                                this.contactsLimits = this.contactsLimitsObj[this.notificationMethods[i].title]
                            }
                        }
                    }
                }
            },
            async uploadFile(file) {
                this.isLoading = true

                if(process.env.VUE_APP_DEBUG) console.log('uploadFile', file)

                if(!file) return

                let uploadUrl = process.env.VUE_APP_API_URL + '/files/upload',
                    filename = file.name

                let formData = new FormData()
                if(process.env.VUE_APP_DEBUG) console.log('file', file)
                formData.append("file", file, filename)

                let fileId = await axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then(function (response) {
                    if(process.env.VUE_APP_DEBUG) console.log('file upload', response)
                    let fileId = response.data.data

                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    return fileId
                }).catch(function (response) {
                    if(process.env.VUE_APP_DEBUG) console.log(response)
                })

                if(fileId) {
                    this.attachments.push(fileId)
                    this.attachmentsData.push({
                        id: fileId,
                        name: filename
                    })

                    this.$refs.file.files = this.attachmentsData
                }

                this.isLoading = false

                // call to save current state of claim
                this.onFormChange()
            },
            async removeFile(fileId) {
                let url = process.env.VUE_APP_API_URL + `/files/${fileId}/remove`

                let removed = await axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('removeFile', response)

                    let r = response.data

                    return r.result === 'SUCCESS'
                })

                if(removed) {
                    this.attachments = this.attachments.filter(file => file != fileId)
                    this.attachmentsData = this.attachmentsData.filter(attach => attach.id != fileId)
                    this.$refs.file.files = this.attachmentsData

                    // is empty
                    if(this.attachmentsData.length == 0) this.$refs.file.clear()

                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('claims.add.notify.remove-file-success'),
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claims.add.notify.remove-file-error'),
                        type: 'error'
                    })
                }

                this.onFormChange()
            },
            setAttacmentTmpName(name) {
                this.attachmentName = name

                this.onFormChange()
            },
            async addedFiles(files) {
                for(let i in files) {
                    await this.uploadFile(files[i])
                }
            },
            onChangeFile(files) {
                this.uploadFile()
            },
            onFileUploadChanged(files) {
                if(process.env.VUE_APP_DEBUG) console.log('onFileUploadChanged', files)
            },
            onSelectChange(id, text, obj) {
                this.templateFieldsFilled = true
                this.setTemplateField(obj.name, text)
            },
            onTemplateFieldChange(value = '', name = '') {
                this.templateFieldsFilled = true

                for(let f in this.templateFields) {
                    if(!this.templateFields[f].field_value) {
                        if(this.templateFields[f].name === 'amount_of_penalty' || this.templateFields[f].name === 'amount_penalty_under_contract' || this.templateFields[f].name === 'contract_penalty' || this.templateFields[f].name === 'amount_of_additional_costs' || this.templateFields[f].name === 'deposit_additional_costs' || this.templateFields[f].name === 'compensation_amount' || this.templateFields[f].name === 'mfo_penalty' || this.templateFields[f].name === 'vk-contract-number-label' || this.templateFields[f].name === 'consumer-code-label') {
                            this.templateFieldsFilled = true
                        } else {
                            break
                        }
                    } else {
                        this.templateFieldsFilled = true
                    }
                }

                if((name === 'deposit_additional_costs' || name === 'amount_of_penalty' || name === 'amount_penalty_under_contract' || name === 'contract_penalty' || name === 'amount_of_additional_costs' || name === 'compensation_amount' || name === 'mfo_penalty') && !value) {
                    value = 0
                    this.setTemplateField(name, value)
                    return
                }

                if(value && name) this.setTemplateField(name, value)
            },
            setTemplateField(name, value) {
                this.templateFields[name].field_value = value

                this.$emit('changedTemplateFields', this.templateFields)
            },
            setTemplateFields() {
                this.templateFields = fields

                this.$emit('changedTemplateFields', this.templateFields)
            },
            onFormChange(value = '', valueText = '', field = {}) {
                this.paidDelivery = this.paidDelivery && this.paidDelivery !== 'false' ? true : false

                if(field.name ?? '' === 'notificationMethodOptionsSelect') {
                    this.contactsLimits = this.contactsLimitsObj[field.item.title]
                    this.claimCost = field.item.cost
                }

                this.onTemplateFieldChange()

                let claim = {
                    recipient: this.claim ? this.claim.recipient : this.recipient,
                    sender: this.claim ? this.claim.sender : this.sender,
                    occurrence_date: this.claim ? this.claim.occurrence_date : this.occurrence_date,
                    essence: this.claim ? this.claim.essence : this.essence,
                    contract_id: this.claim ? this.claim.contract_id : this.contract_id,
                    amount_owed: this.claim ? this.claim.amount_owed : this.amount_owed,
                    attachments: this.claim ? this.claim.attachments : this.attachments,
                    attachmentsData: this.claim ? this.claim.attachmentsData : this.attachmentsData,
                    notification: this.claim ? this.claim.notification : this.notification,
                    paidDelivery: this.claim ? !!this.claim.paid_delivery : this.paid_delivery,
                    notificationMethod: this.claim ? this.claim.notification_method : this.notification_method
                }

                this.$store.commit('setClaim', claim)
                this.$emit('setClaim', this.tmpClaim)

                this.requiredFilled = !!((this.recipient.id || false) && this.occurrence_date && this.essence && this.amount_owed)

                if(!this.claim) this.$emit('getClaim')
            },
            onSubmit() {
                this.loader = true

                if(this.sendClaim) {
                    if(this.isPositiveBalance() && this.isFreeDelivery()) {
                        this.showConfirmFreeSendModal()
                    } else if(!this.isBalanceEnough()) {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.need-more-balance'),
                            type: 'error'
                        })
                        
                        this.stopLoader()
                    } else {
                        this.showChoiceContactsModal()
                    }
                } else {
                    if(!this.claim){
                        this.create()
                    } else {
                        this.update()
                    }
                }

                this.sendClaim = true
            },
            confirmed(data) {
                if(!this.claim){
                    this.create(data)
                } else {
                    this.update(data)
                }
            },
            async create(data = {}) { // if new claim
                // transform occurrence_date to timestamp
                let occurrenceDateTs = new Date(this.occurrence_date),
                    occurrence_date = Math.floor(occurrenceDateTs / 1000),
                    ts = Math.floor(Date.now() / 1000)

                // add notifications
                let notifications = this.notification

                let claim = {
                    user_id: this.$store.state.user.id,
                    recipient: this.recipient.id,
                    sender: this.sender,
                    occurrence_date: occurrence_date,
                    essence: this.essence,
                    amount_owed: this.amount_owed,
                    attachments: this.attachments,
                    notifications: notifications,
                    created: ts,
                    sendClaim: this.sendClaim,
                    notificationMethod: this.notificationMethod,
                    pretrialAgreement: this.pretrialAgreement ? 1 : 0,
                    claimContacts: JSON.stringify(this.contacts),
                    // content: content,
                    template: this.tmpClaim.template
                }

                let formData = new FormData()

                for(let i in claim) {
                    formData.append(i, claim[i])
                }

                for(let f in this.templateFields) {
                    for(let i in this.templateFields[f]) {
                        const field = this.templateFields[f][i];
                        if(Array.isArray(field)) {
                            formData.append(`templateFields[${f}][${i}]`, JSON.stringify(field))
                        } else {
                            formData.append(`templateFields[${f}][${i}]`, field)
                        }
                    }
                }

                for(let i in data) {
                    if(i != 'sign') {
                        formData.append(i, data[i])
                    } else {
                        formData.append('file', data[i], data[i].name)
                    }
                }

                let url = process.env.VUE_APP_API_URL + '/claims/new'

                await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('create', response)

                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        // clear claim in store && localStorage
                        this.$store.commit('clearClaim')

                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('claims.add.notify.create-success'),
                            type: 'success'
                        })

                        this.created = r.data

                        this.$emit('updateCounters')
                        this.$emit('updateBalance')

                        this.$router.push('/claims/outbox')
                        
                        this.stopLoader()
                    } else if(r.message == 'nca invalid') {
                        this.loader = false
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Сертификат недействителен',
                            type: 'error'
                        })
                    } else if(r.message == 'nca revoked') {
                        this.loader = false
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Сертификат отозван',
                            type: 'error'
                        })
                    } else {
                        this.loader = false
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.create-error'),
                            type: 'error'
                        })
                    }
                })

                this.$refs.countModal.loading = false
                this.$refs.countModal.close()
            },
            async update(data = {}) { // if changing saved claim
                // transform occurrence_date to timestamp
                let occurrenceDateTs = new Date(this.occurrence_date),
                    occurrence_date = Math.floor(occurrenceDateTs / 1000),
                    ts = Math.floor(Date.now() / 1000)

                // add notifications
                let notifications = this.notification

                let claim = {
                    id: this.$route.params.id,
                    user_id: this.$store.state.user.id,
                    recipient: this.recipient.id,
                    sender: this.sender,
                    occurrence_date: occurrence_date,
                    essence: this.essence,
                    amount_owed: this.amount_owed,
                    attachments: this.attachments,
                    notifications: notifications,
                    created: ts,
                    sendClaim: this.sendClaim,
                    notificationMethod: this.notificationMethod,
                    pretrialAgreement: this.pretrialAgreement ? 1 : 0,
                    claimContacts: JSON.stringify(this.contacts),
                    // content: content,
                    template: this.tmpClaim.template
                }

                let formData = new FormData()

                for(let i in claim) {
                    formData.append(i, claim[i])
                }

                for(let f in this.templateFields) {
                    for(let i in this.templateFields[f]) {
                        const field = this.templateFields[f][i];
                        if(Array.isArray(field)) {
                            formData.append(`templateFields[${f}][${i}]`, JSON.stringify(field))
                        } else {
                            formData.append(`templateFields[${f}][${i}]`, field)
                        }
                    }
                }

                for(let i in data) {
                    if(i != 'sign') {
                        formData.append(i, data[i])
                    } else {
                        formData.append('file', data[i], data[i].name)
                    }
                }

                let url = process.env.VUE_APP_API_URL + '/claims/update'

                axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('update', response)

                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('claims.add.notify.update-success'),
                            type: 'success'
                        })

                        this.$emit('updateBalance')

                        if(!this.sendClaim) {
                            this.$router.push(`/claims/${claim.id}/edit`)
                            this.$emit('getClaim')
                        } else {
                            this.$router.push('/claims/outbox')
                        }

                        this.stopLoader()
                    } else {
                        this.loader = false
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.update-error'),
                            type: 'error'
                        })
                    }
                })
            },
            cancel() {
                this.$emit('cancel')
            },
            showDeleteDraftModal() {
                this.$store.commit('modalShow', { id: 'confirmDeleteDraftModal' })
            },
            remove() {
                this.$emit('remove')
            },
            async send() {
                let url = process.env.VUE_APP_API_URL + '/claims/' + this.id + '/send'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('send', response)
                }).catch((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('send', response)
                })
            },
            async signed() {
                this.send()
            },
            async sign() {
                
            },
            async getRecipients() {
                this.recipients = []

                let url = process.env.VUE_APP_API_URL + '/recipients'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('getRecipients', response)

                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        let arr = r.data

                        if(process.env.VUE_APP_DEBUG) console.log('getRecipients', 'id', arr)

                        this.recipients = arr
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.get-recipients-error'),
                            type: 'error'
                        })
                    }
                })
            },
            async getRecipient() {
                let url = process.env.VUE_APP_API_URL + '/recipients/' + this.recipientId

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.recipient = r.data
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            async getDeliveryOptions() {
                await this.getCurrentBalance()

                let url = process.env.VUE_APP_API_URL + '/rates/get-current'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        let rate = r.data

                        this.claimCost = rate.rate.claim_cost ?? 0

                        this.paymentOptions.push({ id: true, text: 'paid' })
                    }
                })

                let contactsLimitsObj = await this.$api.getContactsLimits()

                if(contactsLimitsObj.result == 'SUCCESS') {
                    this.contactsLimitsObj = contactsLimitsObj.data
                }
            },
            async getContactsLimits() {
                let contactsLimitsObj = await this.$api.getContactsLimits()

                if(contactsLimitsObj.result == 'SUCCESS') {
                    this.contactsLimitsObj = contactsLimitsObj.data
                }
            },
            async getNotificationMethods() {
                await this.getCurrentBalance()
                await this.getContactsLimits()

                const notificationMethodsRequest = await this.$api.getNotificationMethods()

                if(notificationMethodsRequest.result == 'SUCCESS' && notificationMethodsRequest.data) {
                    this.notificationMethodsArr = notificationMethodsRequest.data

                    for(let i in notificationMethodsRequest.data) {
                        let nMethod = {
                            id: notificationMethodsRequest.data[i].id,
                            text: notificationMethodsRequest.data[i].title,
                            title: notificationMethodsRequest.data[i].title,
                            cost: notificationMethodsRequest.data[i].cost,
                            disabled: parseFloat(this.currentBalance) < parseFloat(notificationMethodsRequest.data[i].cost)
                        }

                        if(nMethod.text == 'free') nMethod.text = this.$t('claims.add.delivery-methods.free')
                        if(nMethod.text == 'electronic') nMethod.text = this.$t('claims.add.delivery-methods.paid', { price: nMethod.cost })
                        if(nMethod.text == 'kazpost') nMethod.text = this.$t('claims.add.delivery-methods.kazpost', { price: nMethod.cost })
                        if(nMethod.text == 'only_kazpost') nMethod.text = this.$t('claims.add.delivery-methods.only_kazpost', { price: nMethod.cost })

                        this.notificationMethods.push(nMethod)

                        if(this.notificationMethod === 0) {
                            this.notificationMethod = nMethod.id
                            this.claimCost = nMethod.cost
                            if(this.contactsLimitsObj[nMethod.title]) this.contactsLimits = this.contactsLimitsObj[nMethod.title]
                        } else if(this.notificationMethod === nMethod.id) {
                            this.claimCost = nMethod.cost
                            if(this.contactsLimitsObj[nMethod.text]) this.contactsLimits = this.contactsLimitsObj[nMethod.text]
                        }
                    }
                }

                if(process.env.VUE_APP_DEBUG) console.log('getNotificationMethods', this.notificationMethods, notificationMethodsRequest)

                return this.notificationMethods
            },
            async getSenderId() {
                let url = process.env.VUE_APP_API_URL + '/recipients/sender-id'

                await axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    this.sender = r.data.id
                    this.senderData = r.data
                    this.hasCredentials = r.hasCredentials
                })

                this.onFormChange()
            },
            prepareEssences(data) {
                let arr = []

                for(let i in data) {
                    let essence = {
                        id: data[i].id,
                        text: data[i].title,
                        desc: data[i].desc,
                        template: data[i].template ? data[i].template : false,
                        language: data[i].tagsObject.language,
                        favourite: data[i].favourite
                    }

                    arr.push(essence)
                }

                return arr
            },
            async refreshFavourites() {
                await this.getEssences()
            },
            async getEssences(isShownFavourite = false) {
                let url = process.env.VUE_APP_API_URL + '/essences/get-favourites'

                await axios.get(url, {
                    params: {
                        userId: this.$store.state.user.id
                    },
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.essences = this.prepareEssences(r.data)
                        if(isShownFavourite)
                            this.essence = !this.essenceBlocked ? this.essences[0].id : this.essence
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.get-essences-error'),
                            type: 'error'
                        })
                    }
                })
            },
            getNotifications() {
                let url = process.env.VUE_APP_API_URL + '/notifications/types-for-claim'

                axios.get(url)
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('getNotifications', response)

                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.notifications = this.prepareNotifications(r.data)
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.get-notifications-error'),
                            type: 'error'
                        })
                    }
                })
            },
            prepareNotifications(arr) {
                let defaultValue = false

                let locales = {
                    email: this.$t('claims.add.notifications.email'),
                    sms: this.$t('claims.add.notifications.sms')
                }

                for(let i in arr) {
                    if(defaultValue === false) defaultValue = arr[i].id
                    arr[i].text = arr[i].text in locales ? locales[arr[i].text] : arr[i].text
                }

                this.$store.commit('getClaim')

                let saved = this.$store.state.claim['notification']

                this.notification = saved ? [saved] : [defaultValue]

                return arr
            },
            showConfirm() {
                this.$store.commit('modalShow', { id: 'confirmSendModal' })
            },
            showSignModal() {
                this.$store.commit('modalShow', { id: 'signModal' })
            },
            showChoiceContactsModal() {
                this.$refs.choiceContactsModal.clear()

                if(parseFloat(this.claimCost) > 0) {
                    if(parseFloat(this.currentBalance) < parseFloat(this.claimCost)) {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claims.add.notify.need-more-balance'),
                            type: 'error'
                        })
    
                        this.loader = false
                    } else {
                        this.$refs.choiceContactsModal.contacts = this.recipient.contacts_send
                        this.$store.commit('modalShow', { id: 'ChoiceContactsModal' })
                    }
                } else if(this.contactsLimits.emails > 0 || this.contactsLimits.phones > 0 || this.contactsLimits.addresses > 0) {
                    this.$refs.choiceContactsModal.contacts = this.recipient.contacts_send
                    this.$store.commit('modalShow', { id: 'ChoiceContactsModal' })
                } else {
                    this.showConfirm()
                }
            },
            showConfirmFreeSendModal() {
                this.$store.commit('modalShow', { id: 'ConfirmFreeSendModal' })
            },
            getTodayDate() {
                let today = new Date(),
                    d = today.getDate(),
                    m = today.getMonth() + 1,
                    y = today.getFullYear()

                return y + '-' + addZeros(m, 2) + '-' + addZeros(d, 2)
            },
            searchRecipientModal() {
                this.$refs.searchRecipientModal.reset()
                this.$refs.searchRecipientModal.getLatestRecipients()
                this.$store.commit('modalShow', { id: 'searchRecipientModal'})
            },
            recipientCardModal() {
                this.$store.commit('modalShow', { id: 'recipientCardModal' })
            },
            async recipientAdded(recipientId) { // when recipient is added or changed
                await this.getRecipients()

                this.recipientId = recipientId
                
                await this.getRecipient()

                this.onFormChange()
            },
            removeRecipient() {
                this.recipientId = ''
                this.recipient = {}

                this.onFormChange()
            },
            async goToEditor() {
                if(!this.requiredFilled || !this.templateFieldsFilled) return

                this.sendClaim = false

                if(this.$route.path === "/claims/new") await this.create()

                if(this.claimId) {
                    this.$router.push('/claims/'+this.claimId+'/template-editor')
                } else {
                    this.$router.push('/claims/'+this.created+'/template-editor')
                }
            },
            stopLoader() {
                this.loader = false
            },
            handleChoiceContctsSelected(contacts) {
                this.contacts = contacts
                this.showConfirm()
            },
            handleChoiceContctsClosed() {
                this.loader = false
            },
            handleFreeSendConfirmed() {
                this.showConfirm()
            },
            handleFreeSendClosed() {
                this.loader = false
            },
            chooseType() {
                this.$store.commit('modalShow', { id: 'chooseEssenceModal' })
            },
            setEssence(id) {
                this.essence = id
                if(this.essenceBlocked) this.blockEssences()
                this.onFormChange()
            },
            blockEssences() {
                this.essenceBlocked = true
            },
            async setRecipient(id) {
                this.recipientId = id
                await this.getRecipient()
                // this.tmpClaim.recipient = id

                this.onFormChange()
            },
            setAmountOwed(amount) {
                this.amount_owed = amount
            },
            async getCurrentBalance() {
                if(this.loadingBalance) return
                this.loadingBalance = true

                let url = process.env.VUE_APP_API_URL + '/accounts/my'
                await axios.get(url, {
                    withCredentials: true,
                })
                .then((response) => {
                    let r = response.data;
                    if(r.result === 'SUCCESS') {
                        let account = r.data;
                        this.currentBalance = account.balance !== null ? account.balance : 0
                    }

                    this.loadingBalance = false
                })
            },
            addAddressOsi() {
                this.$refs.recipientCardModal.clickAddAddress()
            },
            isBalanceEnough() {
                return parseFloat(this.currentBalance) >= parseFloat(this.claimCost)
            },
            isFreeDelivery() {
                return this.claimCost == 0
            },
            isPositiveBalance() {
                return this.currentBalance > 0
            }
        }
    }

</script>

<style scoped>

select:required:invalid {
    color: rgba(0, 0, 0, .5);
}

option[value=""][disabled] {
    display: none;
}
option {
    color: #4f4f4f;
}

.save-loader::after {
    color: #4f4f4f !important;
}

.btn-input {
    border-left: 1px solid #bdbdbd;
    width: 5em;
}

.btn-clear {
    color: #bdbdbd;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    border-top: 1px solid #bdbdbd !important;
    border-bottom: 1px solid #bdbdbd !important;
}

.btn-clear:hover,
.btn-clear:focus {
    color: red;
    background-color: #fff;
    outline: none !important;
}

.btn-clear:active {
    color: red !important;
    background-color: #eeeeee !important;
}

</style>